import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "../styles/navbar.css";
import logo from "./logo.png";
const Navbar = () => {
  const nav = useRef(null);
  const toggle = () => {
    // console.log(nav.current.classList);
    nav.current.classList.toggle("active");
  };
  return (
    <div className="navbar">
      <img src={logo} alt="" className="logo" />
      <ul className="nav-items" ref={nav}>
        <li>
          <Link to="/" className="nav-link">
            Home
          </Link>
        </li>
        <li>
          <a href="#about" className="nav-link">
            About
          </a>
        </li>
        <li>
          <a className="nav-link" href="#services">
            Services
          </a>
        </li>
        <li>
          <a href="#founder" className="nav-link">
            Founder
          </a>
        </li>
        <li>
          <a href="#contact" className="nav-link">
            Contact
          </a>
        </li>
      </ul>
      <input
        hidden=""
        class="check-icon"
        id="check-icon"
        name="check-icon"
        type="checkbox"
        onChange={toggle}
      />
      <label class="icon-menu" for="check-icon">
        <div class="bar bar--1"></div>
        <div class="bar bar--2"></div>
        <div class="bar bar--3"></div>
      </label>
    </div>
  );
};

export default Navbar;
