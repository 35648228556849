import React, { useState } from "react";
import "../styles/home.css";
import founder from "./founder.jpeg";

const Home = () => {
  const [mail, setmail] = useState({
    mail: "",
    subject: "",
    name: "",
    body: "",
  });

  const fun = (e) => {
    setmail({ ...mail, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <div className="main">
        <div className="main-2">
          <h1 className="title">
            <span>MAKING CONNECTIONS </span> DELIVERING RESULTS
          </h1>
          <p className="sub-title">BUILDING YOUR SOCIAL BRAND</p>
        </div>
      </div>

      <div className="services-container-home" id="services">
        <h1 className="service-home-title">
          Our <br /> Services
        </h1>
        <div className="services-home">
          <p>Visa Guidance</p>
          <p>Consultancy Shortlisting</p>
          <p>Scholarship Application </p>
          <p>Application Processing</p>
        </div>
        <div className="services-home">
          <p>Profile Evaluation</p>
          <p>Financial Aid</p>
          <p>Universities Selection </p>
          <p>Accomodation</p>
        </div>
      </div>

      <div className="wrapper" id="about">
        <div className="section-3">
          <h1 className="section3-title">
            <div className="section3-span">Who</div> <br /> We Are ?
          </h1>
          <p className="sec3-para">
            S B Abroad Connectsss is more than just a consultancy; it's a
            catalyst for global aspirations. With a focus on personalized
            service and unwavering dedication, S B Abroad Connectsss is
            committed to helping individuals realize their dreams of studying,
            working, or living abroad.
            <br />
            <br />
            At S B Abroad Connectsss, we understand that each individual's
            journey is unique. That's why we provide personalized guidance and
            support, taking the time to listen to our clients' aspirations and
            tailor our services to meet their specific needs and goals.
            <br />
            <br />
            Our ultimate goal at S B Abroad Connectsss is to empower individuals
            to pursue their dreams with confidence and clarity. Whether it's
            studying abroad, gaining international work experience, or starting
            a new life in a different country, we're here to help our clients
            navigate the complexities of the global landscape and turn their
            dreams into reality.
          </p>
        </div>
      </div>

      <div className="section-4">
        <h1 className="section4-title">OUR VISION</h1>
        <p className="sec4-para">
          We envision a world where every individual has the opportunity to
          pursue their educational and professional aspirations abroad,
          regardless of barriers or boundaries. Our consultancy aims to redefine
          the paradigm of international education and career advancement by
          providing comprehensive, personalized guidance and support to students
          and professionals worldwide.
        </p>
      </div>

      <div className="image-gallery">
        <div className="im-wrap">
          <p className="p">Visa Guidance</p>
          <img
            src="https://cdn.pixabay.com/photo/2017/08/14/22/06/passport-2642172_640.jpg"
            alt=""
            className="img"
          />
        </div>
        <div className="im-wrap">
          <p className="p">Financial Aid</p>
          <img
            src="https://cdn.pixabay.com/photo/2016/04/25/23/53/euro-1353420_640.jpg"
            alt=""
            className="img"
          />
        </div>
        <div className="im-wrap">
          <p className="p">Accomodation</p>
          <img
            src="https://cdn.pixabay.com/photo/2021/12/11/07/59/hotel-6862159_1280.jpg"
            alt=""
            className="img"
          />
        </div>
        <div className="im-wrap">
          <p className="p">Universities Shortlisting</p>
          <img
            src="https://cdn.pixabay.com/photo/2023/09/17/19/10/building-8259184_1280.jpg"
            alt=""
            className="img"
          />
        </div>
        <div className="im-wrap">
          <p className="p">Scholarships</p>
          <img
            src="https://img.freepik.com/premium-photo/aving-money-college-money-jar-with-graduation-hat-top_1091514-421.jpg"
            alt=""
            className="img"
          />
        </div>
        <div className="im-wrap">
          <p className="p">Profile Evaluation</p>
          <img
            src="https://cdn.pixabay.com/photo/2015/05/31/11/45/journal-791286_640.jpg"
            alt=""
            className="img"
          />
        </div>
      </div>

      <div className="section-4">
        <h1 className="section4-title">
          OUR <br /> CLIENTS
        </h1>
        <p className="sec4-para">
          Our firm is committed to providing comprehensive guidance and
          unwavering support, ensuring a seamless transition for our clients. We
          understand that each individual has unique needs and aspirations,
          which is why we offer tailored advice and solutions designed
          specifically for you. For students, we assist in identifying and
          selecting the consultancy best suited to their academic profiles and
          career goals, ensuring they receive the highest quality assistance for
          their international education. For professionals seeking to work
          abroad, we provide expert guidance on job markets, application
          processes, and work permits to enhance their prospects. For those
          looking to migrate, we offer detailed insights into immigration laws,
          visa requirements, and settlement services to facilitate a smooth
          relocation.
        </p>
      </div>

      <div className="section-5" id="founder">
        <div className="wrap">
          <div>
            <h1 className="section4-title">OUR FOUNDER</h1>
            <p className="sec4-para">
              Bajjuri Sai Charan, an energetic graduate in Computer Science and
              Engineering from Anurag University, stands as the influential
              President of the CSI Student Chapter. The Founder's vibrant
              personality, coupled with his dynamic leadership, has earned him
              several accolades, notably the{" "}
              <i>"Best Student Volunteer Award".</i>
            </p>
          </div>
          <div className="founder-details">
            <img src={founder} alt="" />
          </div>
        </div>
        <div className="details">
          <div className="d">
            <i className="fa-solid fa-user"></i>
            <p> Bajjuri Sai Charan</p>
          </div>
          <div className="d">
            <i className="fa-solid fa-phone"></i>
            <p>9100668018</p>
          </div>
          <div className="d">
            <i className="fa-solid fa-envelope"></i>
            <p>saicharan@sbabroadconnectsss.com</p>
          </div>
        </div>
      </div>

      <div className="section-6">
        <div className="section-6-wrap">
          <h1 className="section4-title" style={{ borderColor: "white" }}>
            FUN <br /> FACTS
          </h1>
          <div className="facts">
            <div className="fact">
              <h1 className="numbers">5000+</h1>
              <p className="det">Universities</p>
            </div>
            <div className="fact">
              <h1 className="numbers">10+</h1>
              <p className="det">Countries</p>
            </div>
            <div className="fact">
              <h1 className="numbers">100+</h1>
              <p className="det">Students</p>
            </div>
            <div className="fact">
              <h1 className="numbers">9+</h1>
              <p className="det">Consultancies</p>
            </div>
          </div>
        </div>
      </div>

      <div className="section-7" id="contact">
        <h1 className="section3-title">
          <div className="section3-span">GET</div> <br /> IN TOUCH
        </h1>
        <div className="form">
          <input
            type="text"
            placeholder="Enter Your Email ID"
            value={mail.mail}
            name="mail"
            onChange={fun}
          />
          <input
            type="text"
            placeholder="Enter Your Name"
            value={mail.name}
            name="name"
            onChange={fun}
          />
          <input
            type="text"
            placeholder="Enter the Subject"
            value={mail.subject}
            name="subject"
            onChange={fun}
          />
          <textarea
            name="body"
            id=""
            cols="30"
            rows="10"
            placeholder="Type Your Message here ..."
            value={mail.body}
            onChange={fun}
          ></textarea>
          <div className="buttons">
            <a
              href={`mailto:sbabroadconnectsss@gmail.com?subject=${mail.subject}&body=${mail.body}`}
              className="mail-button"
            >
              Send Mail
            </a>
            <a
              href={`https://forms.gle/XtFio9YTbZYACyp97`}
              className="mail-button2"
            >
              Fill out the Google Form for any Enquires
            </a>
          </div>
        </div>
      </div>

      <div className="footer">
        <h1>
          {" "}
          <i className="fa-regular fa-copyright"></i> SBAbroadConnectsss @ 2024
        </h1>
        <p>All Rights Reserved</p>
        <div className="socials">
          <a
            href="https://wa.me/919100668018"
            style={{ textDecoration: "none" }}
          >
            <i
              className="fa-brands fa-whatsapp"
              style={{ color: "#25D366" }}
            ></i>
          </a>
          <a href="" style={{ textDecoration: "none" }}>
            <i
              className="fa-brands fa-facebook"
              style={{ color: "#1877F2" }}
            ></i>
          </a>
          <a
            href="https://www.instagram.com/s_b_abroad_connectsss?igsh=ZG95M2JycHZtZm50"
            style={{ textDecoration: "none" }}
          >
            <i
              className="fa-brands fa-instagram"
              style={{
                background: "linear-gradient(to right,#8A3AB9, #C13584)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            ></i>
          </a>
          <a
            href="https://www.linkedin.com/company/s-b-abroad-connectsss/"
            style={{ textDecoration: "none" }}
          >
            <i
              className="fa-brands fa-linkedin"
              style={{ color: "#0A66C2" }}
            ></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
